<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader
            class="cursor-pointer"
            @click="searchBlogFormCollapsed = !searchBlogFormCollapsed">
            <span><strong>Blog Ara</strong></span>
            <div class="card-header-actions">
              <font-awesome-icon
                :icon="['fas', 'chevron-up']"
                size="lg"
                v-if="searchBlogFormCollapsed" />
              <font-awesome-icon
                :icon="['fas', 'chevron-down']"
                size="lg"
                v-else />
            </div>
          </CCardHeader>
          <CCollapse :show="searchBlogFormCollapsed">
            <CCardBody>
              <CForm @submit.prevent="searchCar">
                <CRow>
                  <CCol sm="3">
                    <CSelect
                      label="Durum"
                      :value.sync="searchCarForm.status"
                      :options="searchCarFormStatusOptions" />
                  </CCol>
                  <CCol sm="4" md="3">
                    <CSelect
                      label="Marka"
                      :value.sync="searchCarForm.brand"
                      :options="searchCarFormBrandOptions" />
                  </CCol>
                  <CCol sm="5" md="4">
                    <CInput label="Model" v-model="searchCarForm.model" />
                  </CCol>
                  <CCol sm="12" md="2" class="mt-md-4">
                    <CButton
                      type="submit"
                      color="info"
                      class="px-3"
                      style="margin-top: 4px"
                      >Ara
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
      <CCol md="12" v-if="carsGridShow">
        <CCard>
          <CCardHeader>
            <span><strong>Araçlar</strong></span>
          </CCardHeader>
          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: 500px"
              class="ag-theme-balham"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :rowHeight="55"
              :sideBar="sideBar"
              :getContextMenuItems="getGridContextMenuItems"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @grid-ready="onGridReady">
            </ag-grid-vue>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- :closeOnBackdrop="false" -->
    <CModal
      :title="carModalAddUpdateOptions.title"
      size="lg"
      :closeOnBackdrop="false"
      :show.sync="carModalAddUpdate"
      class="carModalAddUpdateForm">
      <CRow>
        <CCol sm="12" lg="4">
          <div class="img-car-container">
            <div class="img-car-upload-delete-container">
              <div class="btn-icon-center">
                <font-awesome-icon
                  id="btnResimYukle"
                  icon="cloud-upload-alt"
                  size="2x"
                  title="Resim Yükle"
                  @click="clickImageUpload()" />
                <font-awesome-icon
                  id="btnResimSil"
                  icon="trash-alt"
                  size="2x"
                  class="ml-4"
                  title="Resmi Sil"
                  v-if="
                    carModalAddUpdateForm.srcImage !==
                    ENV_URL_CARS + 'default_car.png'
                  "
                  @click="clickImageDelete()" />
              </div>
              <input
                id="inputCarImageFile"
                type="file"
                class="files"
                ref="files"
                custom
                v-on:change="selectImage()" />
            </div>
            <CImg :src="carModalAddUpdateForm.srcImage" fluid />
          </div>
        </CCol>
        <CCol sm="12" lg="8">
          <CRow>
            <CCol md="12" lg="6">
              <CSelect
                label="Marka"
                :value.sync="carModalAddUpdateForm.brand"
                :options="carModalAddUpdateFormBrandOptions" />
            </CCol>
            <CCol md="12" lg="6">
              <CInput
                label="Araç Modeli"
                :class="{ shake: carModalAddUpdateOptions.shake.model }"
                v-model="carModalAddUpdateForm.model"
                :value="carModalAddUpdateForm.model"
                :invalid-feedback="carModalAddUpdateFormErrors.model"
                :is-valid="validModel" />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12" lg="6">
              <CSelect
                label="Durum"
                :value.sync="carModalAddUpdateForm.status"
                :options="carModalAddUpdateFormStatusOptions" />
            </CCol>
            <CCol md="12" lg="6">
              <CSelect
                label="Sınıf"
                :value.sync="carModalAddUpdateForm.class"
                :options="carModalAddUpdateFormClassOptions" />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow class="mt-lg-2">
        <CCol sm="4">
          <CSelect
            label="Size"
            :value.sync="carModalAddUpdateForm.size"
            :options="carModalAddUpdateFormSizeOptions" />
        </CCol>
        <CCol sm="4">
          <CSelect
            label="Segment"
            :value.sync="carModalAddUpdateForm.segment"
            :options="carModalAddUpdateFormSegmentOptions" />
        </CCol>
        <CCol sm="4">
          <CSelect
            label="Body"
            :value.sync="carModalAddUpdateForm.body"
            :options="carModalAddUpdateFormBodyOptions" />
        </CCol>
        <CCol sm="4">
          <CInput
            label="Koltuk Sayısı"
            type="number"
            step="1"
            min="1"
            max="10"
            :class="{ shake: carModalAddUpdateOptions.shake.seat }"
            v-model="carModalAddUpdateForm.seat"
            :value="carModalAddUpdateForm.seat"
            :invalid-feedback="carModalAddUpdateFormErrors.seat"
            :is-valid="validSeat" />
        </CCol>
        <CCol sm="4">
          <CSelect
            label="Vites Tipi"
            :value.sync="carModalAddUpdateForm.transmission"
            :options="carModalAddUpdateFormTransmissionOptions" />
        </CCol>
        <CCol sm="4">
          <CSelect
            label="Yakıt Tipi"
            :value.sync="carModalAddUpdateForm.fuel"
            :options="carModalAddUpdateFormFuelOptions" />
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          color="success"
          :disabled="carModalAddUpdateBtnDisable"
          @click="carAddUpdate()"
          >{{
            carModalAddUpdateOptions.process === "post" ? "Ekle" : "Düzenle"
          }}</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Func from "../../func";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";

axios.defaults.withCredentials = true;
Vue.use(VueSweetalert2);

export default {
  name: "BlogSearch",
  data() {
    return {
      ENV_URL_CARS: process.env.VUE_APP_CDN_URL_CARS,
      defaultCarImage: "default_car.png",

      // AG GRID
      carsGridShow: false,
      columnDefs: [
        {
          field: "image",
          cellRenderer: (params) => {
            return (
              '<img src="' +
              this.ENV_URL_CARS +
              params.value +
              '" class="my-1" style="width: auto; height: 45px">'
            );
          },
          headerName: "Araç Resmi",
          width: 110,
        },
        {
          field: "status",
          cellRenderer: (params) => {
            return params.value ? "Aktif" : "Pasif";
          },
          cellStyle: (params) => {
            return params.value
              ? { color: "#2eb85c", fontWeight: "bold" }
              : { color: "#e55353", fontWeight: "bold" };
          },
          headerName: "Durum",
          width: 100,
        },
        {
          field: "brand",
          headerName: "Marka",
          width: 100,
        },
        {
          field: "brandSlug",
          headerName: "Marka Slug",
          hide: true,
          width: 100,
        },
        {
          field: "model",
          headerName: "Model",
          width: 100,
        },
        {
          field: "modelSlug",
          headerName: "Model Slug",
          hide: true,
          width: 100,
        },
        {
          field: "fuel",
          headerName: "Yakıt",
          width: 80,
        },
        {
          field: "transmission",
          headerName: "Vites",
          width: 100,
        },
        {
          field: "class",
          headerName: "Sınıf",
          width: 80,
        },
        {
          field: "segment",
          headerName: "Segment",
          width: 100,
        },
        {
          field: "size",
          headerName: "Size",
          width: 80,
        },
        {
          field: "body",
          headerName: "Kasa",
          width: 100,
        },
        {
          field: "seat",
          headerName: "Koltuk",
          width: 80,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },

      // BLOG ARAMA
      searchBlogFormCollapsed: true,
      searchCarFormStatusOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      searchCarFormBrandOptions: [
        {
          value: "",
          label: "Tümü",
        },
        "Alfa Romeo",
        "Aston Martin",
        "Astra",
        "Audi",
        "Bentley",
        "Bmc",
        "Jeep",
        "Ds Automobiles",
        "Bmw",
        "Bugatti",
        "Cadillac",
        "Chery",
        "Chevrolet",
        "Chrysler",
        "Citroen",
        "Cupra",
        "Dacia",
        "Daihatsu",
        "Ferrari",
        "Fiat",
        "Ford",
        "Geely",
        "Gmc",
        "Honda",
        "Hummer",
        "Hyundai",
        "Infiniti",
        "Isuzu",
        "Jaguar",
        "Karsan",
        "Kia",
        "Lada",
        "Lamborghini",
        "Lancia",
        "Land Rover",
        "Lexus",
        "Lincoln",
        "Lotus",
        "Maserati",
        "Maybach",
        "Mazda",
        "Mclaren",
        "Mercedes",
        "Mercury",
        "Mg",
        "Mini",
        "Mitsubishi",
        "Nissan",
        "Opel",
        "Peugeot",
        "Porsche",
        "Proton",
        "Range Rover",
        "Renault",
        "Rolls-Royce",
        "Saab",
        "Seat",
        "Skoda",
        "Smart",
        "Ssangyong",
        "Subaru",
        "Suzuki",
        "Tata",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
      ],
      searchCarForm: {
        status: "",
        brand: "",
        model: "",
      },
      // ARAÇ EKLEME GÜNCELLEME MODAL
      carModalAddUpdate: false, // Car Modal Add Update
      carModalAddUpdateBtnDisable: false, // Car Modal Add Update button disabled enabled değişkeni
      carModalAddUpdateOptions: {
        // Car Modal Add Update değişkenleri
        process: "",
        title: "",
        shake: {
          model: false,
          seat: false,
        },
      },
      carModalAddUpdateFormBrandOptions: [
        "Alfa Romeo",
        "Aston Martin",
        "Astra",
        "Audi",
        "Bentley",
        "Bmc",
        "Bmw",
        "Jeep",
        "Ds Automobiles",
        "Bugatti",
        "Cadillac",
        "Chery",
        "Chevrolet",
        "Chrysler",
        "Citroen",
        "Cupra",
        "Dacia",
        "Daihatsu",
        "Ferrari",
        "Fiat",
        "Ford",
        "Geely",
        "Gmc",
        "Honda",
        "Hummer",
        "Hyundai",
        "Infiniti",
        "Isuzu",
        "Jaguar",
        "Karsan",
        "Kia",
        "Lada",
        "Lamborghini",
        "Lancia",
        "Land Rover",
        "Lexus",
        "Lincoln",
        "Lotus",
        "Maserati",
        "Maybach",
        "Mazda",
        "Mclaren",
        "Mercedes",
        "Mercury",
        "Mg",
        "Mini",
        "Mitsubishi",
        "Nissan",
        "Opel",
        "Peugeot",
        "Porsche",
        "Proton",
        "Range Rover",
        "Renault",
        "Rolls-Royce",
        "Saab",
        "Seat",
        "Skoda",
        "Smart",
        "Ssangyong",
        "Subaru",
        "Suzuki",
        "Tata",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
      ],
      carModalAddUpdateFormStatusOptions: [
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      carModalAddUpdateFormClassOptions: [
        {
          value: "economic",
          label: "Ekonomik",
        },
        {
          value: "luxury",
          label: "Lüks",
        },
        {
          value: "medium",
          label: "Standart",
        },
        {
          value: "commercial",
          label: "Ticari",
        },
      ],
      carModalAddUpdateFormSegmentOptions: [
        {
          value: "A",
          label: "A",
        },
        {
          value: "B",
          label: "B",
        },
        {
          value: "C",
          label: "C",
        },
        {
          value: "D",
          label: "D",
        },
        {
          value: "E",
          label: "E",
        },
        {
          value: "F",
          label: "F",
        },
        {
          value: "G",
          label: "G",
        },
        {
          value: "M",
          label: "M",
        },
        {
          value: "J",
          label: "J",
        },
      ],
      carModalAddUpdateFormSizeOptions: [
        {
          value: "small",
          label: "Küçük",
        },
        {
          value: "medium",
          label: "Orta",
        },
        {
          value: "large",
          label: "Büyük",
        },
        {
          value: "stationWagon",
          label: "Station Wagon",
        },
        {
          value: "premium",
          label: "Premium",
        },
        {
          value: "minivan",
          label: "Minivan",
        },
        {
          value: "Monospace",
          label: "monospace",
        },
        {
          value: "suv",
          label: "Suv",
        },
      ],
      carModalAddUpdateFormBodyOptions: [
        {
          value: "sedan",
          label: "Sedan",
        },
        {
          value: "hatchback",
          label: "Hatchback",
        },
        {
          value: "crossover",
          label: "Crossover",
        },
        {
          value: "suv",
          label: "Suv",
        },
        {
          value: "wagon",
          label: "Wagon",
        },
        {
          value: "coupe",
          label: "Coupe",
        },
        {
          value: "pickup",
          label: "Pickup",
        },
        {
          value: "compact",
          label: "Compact",
        },
        {
          value: "cabriolet",
          label: "Cabriolet",
        },
        {
          value: "van",
          label: "Van",
        },
      ],
      carModalAddUpdateFormTransmissionOptions: [
        {
          value: "manuel",
          label: "Manuel",
        },
        {
          value: "automatic",
          label: "Otomatik",
        },
      ],
      carModalAddUpdateFormFuelOptions: [
        {
          value: "gas",
          label: "Benzin",
        },
        {
          value: "diesel",
          label: "Dizel",
        },
        {
          value: "lpg",
          label: "LPG",
        },
        {
          value: "hybrit",
          label: "Hibrit",
        },
        {
          value: "unspecified",
          label: "Belirtilmemiş",
        },
      ],
      carModalAddUpdateFormErrors: {
        model: "",
        seat: "",
      },
      carModalAddUpdateForm: {
        _id: "",
        srcImage: "",
        files: null,
        brand: "",
        brandSlug: "",
        model: "",
        modelSlug: "",
        status: true,
        class: "",
        segment: "",
        size: "",
        body: "",
        seat: 1,
        transmission: "",
        fuel: "",
      },
    };
  },
  components: {
    AgGridVue,
  },
  watch: {
    "carModalAddUpdateForm.brand": function () {
      if (this.carModalAddUpdateForm.brand != "")
        this.carModalAddUpdateForm.brandSlug = Func.slugify(
          this.carModalAddUpdateForm.brand
        );
      else this.carModalAddUpdateForm.brandSlug = "";
    },
    "carModalAddUpdateForm.model": function () {
      if (this.carModalAddUpdateForm.model != "")
        this.carModalAddUpdateForm.modelSlug = Func.slugify(
          this.carModalAddUpdateForm.model
        );
      else this.carModalAddUpdateForm.modelSlug = "";
    },
  },
  methods: {
    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    getGridContextMenuItems(params) {
      var _this = this;
      var result = [
        {
          name: "Aracı Sil",
          action: function () {
            Vue.swal({
              icon: "error",
              title: "Araç Sil",
              html:
                '<img src="' +
                _this.ENV_URL_CARS +
                params.node.data.image +
                '" class="mb-3" style="width: 120px; height: auto;"/><br><strong>' +
                params.node.data.brand +
                "</strong> marka <strong>" +
                params.node.data.model +
                "</strong> model aracı silmek istiyor musunuz?",
              showDenyButton: true,
              confirmButtonText: "Evet",
              denyButtonText: "Hayır",
            }).then((result) => {
              if (result.isConfirmed)
                _this.carDelete({ _id: params.node.data._id });
            });
          },
        },
        {
          name: "Aracı Düzenle",
          action: function () {
            _this.cleanCarModalAddUpdateForm();
            _this.carModalAddUpdateOptions.process = "put";
            _this.carModalAddUpdateOptions.title = "Aracı Güncelle";
            _this.carModalAddUpdateForm._id = params.node.data._id;
            _this.carModalAddUpdateForm.brand = params.node.data.brand;
            _this.carModalAddUpdateForm.brandSlug = params.node.data.brandSlug;
            _this.carModalAddUpdateForm.model = params.node.data.model;
            _this.carModalAddUpdateForm.modelSlug = params.node.data.modelSlug;
            _this.carModalAddUpdateForm.class = params.node.data.class;
            _this.carModalAddUpdateForm.segment = params.node.data.segment;
            _this.carModalAddUpdateForm.size = params.node.data.size;
            _this.carModalAddUpdateForm.body = params.node.data.body;
            _this.carModalAddUpdateForm.seat = params.node.data.seat;
            _this.carModalAddUpdateForm.transmission =
              params.node.data.transmission;
            _this.carModalAddUpdateForm.fuel = params.node.data.fuel;
            _this.carModalAddUpdateForm.srcImage =
              _this.ENV_URL_CARS + params.node.data.image;
            _this.carModalAddUpdateForm.status = params.node.data.status;
            _this.carModalAddUpdate = true;
          },
        },
        {
          name: "Kopyala",
          action: function () {
            _this.gridApi.copySelectedRangeToClipboard();
          },
        },
        "separator",
        "chartRange",
      ];
      return result;
    },
    validModel(val) {
      if (val) {
        if (val.toString().length > 30) {
          this.carModalAddUpdateFormErrors.model =
            "Bu alana en fazla 30 karakter girilebilir!";
          return false;
        } else {
          this.carModalAddUpdateFormErrors.model = "";
          return true;
        }
      } else {
        this.carModalAddUpdateFormErrors.model = "Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validSeat(val) {
      if (val) {
        if (val > 10) {
          this.carModalAddUpdateFormErrors.seat =
            "Koltuk sayısı en fazla 10 olabilir!";
          return false;
        } else if (!val.toString().match(/^[0-9]+$/g)) {
          this.carModalAddUpdateFormErrors.seat =
            "Bu alana yalnızca sayı değeri girilebilir!";
          return false;
        } else {
          this.carModalAddUpdateFormErrors.seat = "";
          return true;
        }
      } else {
        this.carModalAddUpdateFormErrors.seat = "Bu alan boş bırakılamaz!";
        return false;
      }
    },
    cleanCarModalAddUpdateForm() {
      this.carModalAddUpdateForm._id = "";
      this.carModalAddUpdateForm.srcImage =
        this.ENV_URL_CARS + this.defaultCarImage;
      this.carModalAddUpdateForm.files = null;
      this.carModalAddUpdateForm.brand =
        this.carModalAddUpdateFormBrandOptions[0];
      this.carModalAddUpdateForm.brandSlug = Func.slugify(
        this.carModalAddUpdateFormBrandOptions[0]
      );
      this.carModalAddUpdateForm.model = "";
      this.carModalAddUpdateForm.modelSlug = "";
      this.carModalAddUpdateForm.status =
        this.carModalAddUpdateFormStatusOptions[0].value;
      this.carModalAddUpdateForm.class =
        this.carModalAddUpdateFormClassOptions[0].value;
      this.carModalAddUpdateForm.segment =
        this.carModalAddUpdateFormSegmentOptions[0].value;
      this.carModalAddUpdateForm.size =
        this.carModalAddUpdateFormSizeOptions[0].value;
      this.carModalAddUpdateForm.body =
        this.carModalAddUpdateFormBodyOptions[0].value;
      this.carModalAddUpdateForm.seat = 1;
      this.carModalAddUpdateForm.transmission =
        this.carModalAddUpdateFormTransmissionOptions[0].value;
      this.carModalAddUpdateForm.fuel =
        this.carModalAddUpdateFormFuelOptions[0].value;

      document.getElementById("inputCarImageFile").value = "";
    },
    clickImageUpload() {
      document.getElementById("inputCarImageFile").click();
    },
    clickImageDelete() {
      this.carModalAddUpdateForm.srcImage =
        this.ENV_URL_CARS + this.defaultCarImage;
      this.carModalAddUpdateForm.files = null;
      document.getElementById("inputCarImageFile").value = "";
    },
    selectImage() {
      const _this = this;
      const fileImage = this.$refs.files.files[0];

      if (fileImage.size > 1048576) {
        Vue.swal({
          icon: "error",
          title: "Araç Ekle - Resim",
          html: "Maksimum 1 MB resim yükleyebilirsiniz!",
          confirmButtonText: "Tamam",
        });
      } else {
        if (
          fileImage.type === "image/png" ||
          fileImage.type === "image/jpg" ||
          fileImage.type === "image/jpeg"
        ) {
          let reader = new FileReader();

          reader.onload = function (e) {
            _this.carModalAddUpdateForm.srcImage = e.target.result;
            _this.carModalAddUpdateForm.files = fileImage;
          };
          reader.readAsDataURL(fileImage);
        } else {
          Vue.swal({
            icon: "error",
            title: "Araç Ekle - Resim",
            html: "Yalnızca png, jpg ve jpeg formatlı resimleri yükleyebilirsiniz!",
            confirmButtonText: "Tamam",
          });
        }
      }
    },
    searchCar() {
      this.$Progress.start();

      axios
        .get(process.env.VUE_APP_API_URL + "admin/cars", {
          params: this.searchCarForm,
        })
        .then((response) => {
          this.$Progress.finish();
          this.rowData = response.data.carList;
          this.searchCarFormCollapsed = false;
          this.carsGridShow = true;
        });
    },
    carAddUpdate() {
      // Modal Form içinde eğer model alanında hata var ise shake yapıyoruz.
      if (this.carModalAddUpdateFormErrors.model !== "") {
        this.carModalAddUpdateOptions.shake.model = true;

        setTimeout(() => {
          this.carModalAddUpdateOptions.shake.model = false;
        }, 1000);
      }

      // Modal Form içinde eğer seat alanında hata var ise shake yapıyoruz.
      if (this.carModalAddUpdateFormErrors.seat !== "") {
        this.carModalAddUpdateOptions.shake.seat = true;

        setTimeout(() => {
          this.carModalAddUpdateOptions.shake.seat = false;
        }, 1000);
      }

      // Modal Form içinde eğer hata var ise return ile işlemi durduruyoruz.
      if (
        this.carModalAddUpdateFormErrors.model !== "" ||
        this.carModalAddUpdateFormErrors.seat !== ""
      )
        return;

      this.$Progress.start();
      this.carModalAddUpdateBtnDisable = true; // Modal'daki post button'u disable ettik.

      var formData = new FormData();

      if (this.carModalAddUpdateOptions.process === "put")
        formData.append("_id", this.carModalAddUpdateForm._id);

      if (
        this.carModalAddUpdateForm.files !== null &&
        this.carModalAddUpdateForm.files.size > 0
      )
        formData.append("image", this.carModalAddUpdateForm.files);
      else if (
        this.carModalAddUpdateForm.srcImage ===
        this.ENV_URL_CARS + this.defaultCarImage
      )
        formData.append("image", this.defaultCarImage);

      formData.append("brand", this.carModalAddUpdateForm.brand);
      formData.append("brandSlug", this.carModalAddUpdateForm.brandSlug);
      formData.append("model", this.carModalAddUpdateForm.model);
      formData.append("modelSlug", this.carModalAddUpdateForm.modelSlug);
      formData.append("status", this.carModalAddUpdateForm.status);
      formData.append("class", this.carModalAddUpdateForm.class);
      formData.append("segment", this.carModalAddUpdateForm.segment);
      formData.append("size", this.carModalAddUpdateForm.size);
      formData.append("body", this.carModalAddUpdateForm.body);
      formData.append("seat", this.carModalAddUpdateForm.seat);
      formData.append("transmission", this.carModalAddUpdateForm.transmission);
      formData.append("fuel", this.carModalAddUpdateForm.fuel);

      axios[this.carModalAddUpdateOptions.process](
        process.env.VUE_APP_API_URL + "admin/cars",
        formData,
        { headers: { "Content-Type": false } }
      ).then((response) => {
        this.$Progress.finish();
        this.carModalAddUpdateBtnDisable = false; // Modal'daki post button'u enable ettik.

        if (response.data.result === "success") {
          if (this.carsGridShow) this.searchCar(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
          this.carModalAddUpdate = false; // Modal'ı kapattık
        }

        Vue.swal({
          icon: response.data.result,
          title:
            "Araç " + this.carModalAddUpdateOptions.process === "post"
              ? "Ekle"
              : "Düzenle",
          html: response.data.message,
          confirmButtonText: "Tamam",
        });
      });
    },
    carDelete(params) {
      this.$Progress.start();

      axios
        .delete(process.env.VUE_APP_API_URL + "admin/cars/" + params._id)
        .then((response) => {
          this.$Progress.finish();
          this.searchCar();

          Vue.swal({
            icon: response.data.result,
            title: "Araç Sil",
            html: response.data.message,
            confirmButtonText: "Tamam",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer !important;
}
</style>
